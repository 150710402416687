import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import SideBar from './sidebar';
import NavBar from './navbar';
import Footer from './footer';

 function App() {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  const orderPath = '/order';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    if (userType == 2 ) {
      navigate(orderPath);
    }
  }, [userId, navigate]);

  const [contacts, setData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://007punjabicafe.com/api/contacts/');
        setData(response.data);
        console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();
  }, []);
  

  const downloadExcel = () => {
    const fileName = "007_WhatsApp_contacts.xlsx";

    const columns = ["Name", "WhatsApp", "Contact", "Address"]; 

    const data = [columns];
    if (contacts && Array.isArray(contacts.data)) {
       
        contacts.data.forEach(contact => {
            data.push([
                contact.customer_name || '', 
                contact.customer_whatsapp || '', 
                contact.customer_phone || '',
                contact.customer_address || '' 
            ]);
        });
    }

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Contacts");

    XLSX.writeFile(wb, fileName);
}



  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    <SideBar />

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Contacts
                <button class="btn btn-warning btn-sm ms-auto" style={{float:'right',}} onClick={downloadExcel}> <i class="fa fa-download"></i></button>
              </h6>
                   </div>
            <div class="card-body px-0 pt-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center justify-content-center mb-0">
                  <thead>
                 
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">WhatsApp</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder  opacity-7">Contact</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Address</th>
                      <th></th>
                    </tr>
                  
                  </thead>
                  <tbody>
                  {contacts && contacts.data && contacts.data.map(contact => (
                    <tr>
                       <td>
                        <span class="text-xs font-weight-bold">{contact.customer_name}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{contact.customer_whatsapp}</span>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">{contact.customer_phone}</span>
                      </td>
                     
                      <td>
                        <span class="text-xs font-weight-bold">{contact.customer_address}</span>
                      </td>
                     
                      
                    </tr>
                    ))}
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
   
      <Footer />
    </div>
  </main>

  </div>
  )
  
}
export default App;