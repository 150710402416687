import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';

function App() {
  
  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  const orderPath = '/order';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    if (userType == 2 ) {
      navigate(orderPath);
    }
  }, [userId, navigate]);

  const [reportData, setReportData] = useState(null);

  useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get('https://007punjabicafe.com/api/reports/');
      setReportData(response.data);
      console.log("Reports " + response.data.data.dine[0].order_amount);
    } catch (error) {
      
      console.error("Error fetching data:", error);
    }
  };

  fetchData();

  const intervalId = setInterval(() => {
    fetchData();
  }, 1000);
  return () => clearInterval(intervalId);

}, []);

  const [hutData, setData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://007punjabicafe.com/api/dine/hut/');
        setData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(intervalId);

  }, []);

  const [rfTableData, setrfTableData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://007punjabicafe.com/api/dine/roof_top/');
        setrfTableData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(intervalId);

  }, []);

  const [outerData, setouterData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://007punjabicafe.com/api/dine/outer/');
        setouterData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(intervalId);

  }, []);

  const [acHallData, setacHallData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://007punjabicafe.com/api/dine/ac_hall/');
        setacHallData(response.data);
        //console.log(response.data);
      } catch (error) {
      } 
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(intervalId);
    
  }, []);


  
  return (
   
    <div class="g-sidenav-show bg-gray-100" id="body">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}

    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4" >
    <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">WhatsApp Order</p>
                    {reportData && reportData.data && reportData.data.whatsapp.length > 0 ? (
                        <h5 class="font-weight-bolder">₹ {reportData.data.whatsapp[0].order_amount}</h5>
                      ) : ( <p>Loading...</p>
                    )}
                    
                    <p class="text-sm mb-0">

                      {reportData && reportData.data && reportData.data.whatsapp.length > 0 ? (
                        <span class="font-weight-bold">{reportData.data.whatsapp[0].order_count} orders</span> 
                        ) : ( <p>Loading...</p>
                      )}

                    </p>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                    <i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Website Orders</p>
                    
                    {reportData && reportData.data && reportData.data.website.length > 0 ? (
                        <h5 class="font-weight-bolder">₹ {reportData.data.website[0].order_amount}</h5>
                      ) : ( <p>Loading...</p>
                    )}
                    
                    <p class="text-sm mb-0">

                      {reportData && reportData.data && reportData.data.website.length > 0 ? (
                        <span class="font-weight-bold">{reportData.data.website[0].order_count} orders</span> 
                        ) : ( <p>Loading...</p>
                      )}

                   </p>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                    <i class="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Dine In</p>
                    
                    {reportData && reportData.data && reportData.data.dine.length > 0 ? (
                        <h5 class="font-weight-bolder">₹ {reportData.data.dine[0].order_amount}</h5>
                      ) : ( <p>Loading...</p>
                    )}
                    
                    <p class="text-sm mb-0">

                      {reportData && reportData.data && reportData.data.dine.length > 0 ? (
                        <span class="font-weight-bold">{reportData.data.dine[0].order_count} orders</span> 
                        ) : ( <p>Loading...</p>
                      )}

                    </p>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                    <i class="ni ni-paper-diploma text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Total Orders</p>

                    {reportData !== null ? (
                      <h5 class="font-weight-bolder">
                        ₹ {reportData.data.dine[0].order_amount +
                        reportData.data.whatsapp[0].order_amount +
                        reportData.data.website[0].order_amount}
                      </h5>
                      ) : ( <p>Loading...</p>
                    )}
                   
                     <p class="text-sm mb-0">
                     {reportData !== null ? (
                       <span class="font-weight-bold"> 
                        {reportData.data.dine[0].order_count +
                        reportData.data.whatsapp[0].order_count +
                        reportData.data.website[0].order_count} orders
                      </span> 
                      ) : ( <p>Loading...</p>
                    )}
                   
                     </p>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                    <i class="ni ni-cart text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
     
    <div class="row mt-8">
	  <h6 class="font-weight-bolder">HUT</h6>
        
    {hutData && hutData.data && hutData.data.filter(hut => hut.hut_status == 'yes').map(hut => (

        <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4 mt-2"  key={hut.hut_id}>
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-7">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">{hut.hut_name}</p>
                    <h6 class="font-weight-bolder">
                     {hut.hut_amount}
                    </h6>
                    
                  </div>
                </div>
                <div class="col-5 text-end">
                  <div class="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                    <i class="ni ni-shop text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
                <p class="mb-0 text-sm">
                      <span class="text-success text-sm font-weight-bolder">{hut.hut_order_s_time} </span>  {hut.hut_timing}
                    </p>
              </div>
            </div>
          </div>
        </div>
        
        ))}

		</div>
		
        
	  
	<div class="row mt-5">
	  <h6 class="font-weight-bolder">ROOF TOP</h6>
        
    {rfTableData && rfTableData.data && rfTableData.data.filter(rfTable => rfTable.table_status == 'yes').map(rfTable => (

        <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4 mt-2" key={rfTable.table_id}>
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-7">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">{rfTable.table_name}</p>
                    <h6 class="font-weight-bolder">
                    {rfTable.table_amount}
                    </h6>
                    
                  </div>
                </div>
                <div class="col-5 text-end">
                  <div class="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                    <i class="fa fa-table text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
                <p class="mb-0 text-sm">
                      <span class="text-success text-sm font-weight-bolder">{rfTable.table_order_s_time} </span> {rfTable.table_timing}
                    </p>
              </div>
            </div>
          </div>
        </div>
		    
        ))}
		
        
    </div>
	  
	<div class="row mt-5">
	  <h6 class="font-weight-bolder">OUTER</h6>

    {outerData && outerData.data && outerData.data.filter(outer => outer.table_status == 'yes').map(outer => (

		
        <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4 mt-2" key={outer.table_id}>
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-7">
                 <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">{outer.table_name}</p>
                    <h6 class="font-weight-bolder">
                    {outer.table_amount}
                    </h6>
                   
                  </div>
                </div>
                <div class="col-5 text-end">
                  <div class="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                    <i class="fa fa-table text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
                <p class="mb-0 text-sm">
                      <span class="text-success text-sm font-weight-bolder">{outer.table_order_s_time} </span> {outer.table_timing}
                    </p>
              </div>
            </div>
          </div>
        </div>

        ))}
       
    </div>
	  
	<div class="row mt-5">
	  <h6 class="font-weight-bolder">AC HALL</h6>
        
    {acHallData && acHallData.data && acHallData.data.filter(acHall => acHall.table_status == 'yes').map(acHall => (


        <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4 mt-2" key={acHall.table_id}>
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-7">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">{acHall.table_name}</p>
                    <h6 class="font-weight-bolder">
                    {acHall.table_amount}
                    </h6>
                    
                  </div>
                </div>
                <div class="col-5 text-end">
                  <div class="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                    <i class="fa fa-table text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
                <p class="mb-0 text-sm">
                      <span class="text-success text-sm font-weight-bolder">{acHall.table_order_s_time}</span> {acHall.table_timing}
                    </p>
              </div>
            </div>
          </div>
        </div>

         ))}

    </div>
      <Footer />
    </div>
  </main>

  </div>
  
  );
}

export default App;
