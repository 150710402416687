
import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
ReactSession.setStoreType("localStorage");

const App = () => {
 
  const initialvalue={email: '', password:''};
  const [formvalue, setvalue]=useState(initialvalue);
  const [formError, setError]=useState({});
  const [formsubmit, setsubmit]=useState(false);
  const navigate = useNavigate();
  if(!ReactSession.get("userId")){
    ReactSession.set("userId","0");
  }
  else{
    ReactSession.set("userId", ReactSession.get("userId"));
  }
  
  const handleChange = (e) => {
    const { name, value } =e.target;
        setvalue({...formvalue, [name]: value});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setsubmit(true);

    try {
      const response = await axios.post('https://007punjabicafe.com/api/authentication/', formvalue);
      
      if(response.data.status === 'success' ){
        console.log('Login successful!', response.data.data.user_id);
        ReactSession.set("userId", response.data.data.user_id);
        ReactSession.set("userName", response.data.data.user_name);
        ReactSession.set("userType", response.data.data.user_type);
        if(response.data.data.user_type === '1'){
          navigate('/dashboard');
        }
        if(response.data.data.user_type === '2'){
          navigate('/order');
        }
      }else{
        console.log(response.data.error.message);
        ReactSession.set("userId", "0");
      }

      setError(validate(response));
      
      } catch (error) {
        console.error('Login failed!', error);
        ReactSession.set("userId", "0");
      }

    
  };
  
  const validate=(response)=>{
    const errors={};
      if(response.data.error){
        errors.name = response.data.error.message;
      }
      return errors;
  }

  useEffect(()=>{
    console.log(formError);
    if(Object.keys(formError).length ===0 & formsubmit){
      console.log(formvalue);
    }
  });
  
  const { id } = useParams();

  if(id == 'logout'){
    ReactSession.set("userId", "0");
  }
  return (

    
  <main class="main-content  mt-0">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
              <div class="card card-plain">
                <div class="card-header pb-0 text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form  onSubmit={handleSubmit}>
                    <div class="mb-3">
                      <input type="email" class="form-control form-control-lg" name="email" value={formvalue.email} onChange={handleChange} placeholder="Email" aria-label="Email" />
                    </div>
                    <div class="mb-3">
                      <input type="password" class="form-control form-control-lg" name="password" value={formvalue.password} onChange={handleChange} placeholder="Password" aria-label="Password" />
                    </div>
                  
                    <div class="text-center">
                      <button type="submit" class="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0">Sign in</button>
                    </div>
                    <p style={{ color: 'red' }}>{formError.name}</p>
                  </form>
                </div>
                <div class="card-footer text-center pt-0 px-lg-2 px-1">
                  <p class="mb-4 text-sm mx-auto">
                    Forgot Password?
                    <a href="javascript;"  class="text-primary text-gradient font-weight-bold"> Reset Password</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
              <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden" >
                <span class="mask bg-gradient-primary opacity-6"></span>
                <h4 class="mt-5 text-white font-weight-bolder position-relative">"Welcome to 007 Punjabi Cafe"</h4>
                <p class="text-white position-relative">Restaurant managing System</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>


  );
};

export default App;
