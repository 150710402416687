import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession }  from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import SideBar from './sidebar';
import EsideBar from './esidebar';
import NavBar from './navbar';
import Footer from './footer';

function App() {

  const userId = ReactSession.get("userId");
  const userType = ReactSession.get("userType");
  const navigate = useNavigate();
  const indexPath = '/';
  
  useEffect(() => {
    if (userId == 0 ) {
      navigate(indexPath);
    }
    
  }, [userId, navigate]);
  

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [position, setPosition] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [address, setAddress] = useState('');
  const [pin, setPin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');


  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handlePinChange = (event) => {
    setPin(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('position', position);
    formData.append('address', address);
    formData.append('state', state);
    formData.append('country', country);
    formData.append('pin', pin);
    formData.append('password', password);

    try {
      const response = await axios.post('https://007punjabicafe.com/api/employees/add-employee/', formData);
      console.log(response.data);
      
      if(response.data.status == 'success'){
        navigate('/employee');
      }else{
        setError(response.data.error.message);
      }

    } catch (error) {
      console.error('Error uploading data:', error);
    }
  };

  return (
    <div class="g-sidenav-show   bg-gray-100">
    <div class="min-height-300 bg-primary position-absolute w-100"></div>
    { userType === '1' ?(
    <SideBar />
      ) : (< EsideBar />
     )}
    <main class="main-content position-relative border-radius-lg ">
    
    <NavBar />
   
    <div class="container-fluid py-4">
    <div class="row">
        <div class="col-md-12  order-lg-1 order-2">
          <div class="card">
            <div class="card-body">
              <p class="text-uppercase text-sm">Employee Details</p>
              <form onSubmit={handleFormSubmit}>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Name</label>
                        <input class="form-control" type="text" placeholder='Enter employee name...' value={name} onChange={handleNameChange} required /> 
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Email address</label>
                        <input class="form-control" type="email" placeholder='Enter employee email...' value={email} onChange={handleEmailChange}  required /> 
                     
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Password</label>
                        <input class="form-control" type="password" placeholder='Enter employee password...' value={password} onChange={handlePasswordChange}  required /> 
                     
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Phone Number</label>
                        <input class="form-control" type="text" placeholder='Enter employee phone...' value={phone} onChange={handlePhoneChange} /> 
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Position</label>
                        <select class="form-control"  onChange={handlePositionChange} required >
                          <option>Manager</option>
                          <option>Cashier</option>
                          <option>Chef</option>
                          <option>Waiter</option>
                          <option>Cleaning</option>
                          <option>Other</option>
                        </select>
                  </div>
                </div>
              </div>
              <hr class="horizontal dark"/>
              <p class="text-uppercase text-sm">Contact Information</p>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Address</label>
                        <input class="form-control" type="text" placeholder='Enter employee address...' value={address} onChange={handleAddressChange} /> 
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">State</label>
                        <input class="form-control" type="text" placeholder='Enter employee state...' value={state} onChange={handleStateChange} /> 
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Country</label>
                        <input class="form-control" type="text" placeholder='Enter employee country...' value={country} onChange={handleCountryChange} /> 
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="example-text-input" class="form-control-label">Postal code</label>
                        <input class="form-control" type="text" placeholder='Enter employee pincode...' value={pin} onChange={handlePinChange} /> 
                  </div>
                </div>
                <div class="text-center">
                   <button className="btn btn-primary btn-sm mt-3 px-6" style={{marginLeft:'50px'}}>Add Employee</button>
                   <p style={{ color: 'red' }}>{error}</p>
                   </div>
              </div>
              </form>
            </div>
          </div>
        </div>
        
      </div>
      
		
        
	
	  

	  
	
      <Footer />
    </div>
  </main>

  </div>
  )
}
export default App;