

import React from 'react';

const Footer = () => {
  return (
      <footer class="footer pt-3  ">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-12 mb-lg-0 mb-4">
              <div class="copyright text-center text-sm text-muted text-lg-start">
                © 2024 007 Punjabi Cafe All Rights reserved, made with <i class="fa fa-heart"></i> by <a href="https://sninfoserv.com" class="font-weight-bold" rel="noreferrer" target="_blank">SN Infoserv</a> for a better websolutions.
              </div>
            </div>
          </div>
        </div>
      </footer>
  );
};

export default Footer;


